import React, { useContext } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import * as prismic from "@prismicio/client";
import { PrismicText, PrismicLink } from "@prismicio/react";
import styles from "./CtaBar.module.css";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";
interface ICtaBarProps {
  title: string;
  description: string;
  ctaText: string;
  ctaLink: string;
}
/**
 * @typedef {import("@prismicio/client").Content.CtaBarSlice} CtaBarSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CtaBarSlice>} CtaBarProps
 * @param {CtaBarProps}
 */
const CtaBar = ({ slice, settingss, anoter }) => {
  return (
    <Box className={styles.ctaBar}>
      <FadeInWhenVisible>
        <Box className={["container", styles.ctaBar__container].join(" ")}>
          <Flex className={styles.ctaBar__inner}>
            <Box className={styles.ctaBar__content}>
              <Text textStyle="h2" as="h2" marginBottom="10px">
                <PrismicText field={slice.primary.title} />
              </Text>
              <Text textStyle="p" as="p" marginBottom="0">
                <PrismicText field={slice.primary.subtitle} />
              </Text>
            </Box>
            <Box className={styles.ctaBar__buttons}>
              {slice.primary.show_modal_cta ? (
                <ScrollLink
                  className="button button--light button--large"
                  to="calendlyBooking"
                  smooth
                  duration={500}
                >
                  Arrange a Call
                </ScrollLink>
              ) : (
                <>
                  {slice.primary.show_cta &&
                    prismic.asText(slice.primary.ctatext) && (
                      <PrismicLink
                        className="button button--light button--large"
                        field={slice.primary.ctalink}
                      >
                        <PrismicText field={slice.primary.ctatext} />
                      </PrismicLink>
                    )}
                </>
              )}
            </Box>
          </Flex>
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default CtaBar;
